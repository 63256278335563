import React from "react";

//Style
import "./JobOfferInfoSection.css";

const JobOfferInfoSection = ({ sectionData }) => {
  return (
    <section className="job-offer-info-section">
      <div className="job-offer-info-subcontainer">
        <div className="joi-job-info-container">
          <h1 className="joi-skill-title">
            {sectionData && sectionData.joisd_basic_info.joisd_bi_title}
          </h1>
          <div className="joi-ji-grid">
            {sectionData &&
              sectionData.joisd_basic_info.joisd_bi_info_data.map(
                (infoData, i) => {
                  return (
                    <p key={i} className="joi-ji-txt">
                      {infoData.joisd_bi_id_txt}
                    </p>
                  );
                }
              )}
          </div>
        </div>
        <div className="joi-skill-container">
          <h1 className="">Habilidades requeridas</h1>
          <div className="joi-skill-elm-container">
            {sectionData &&
              sectionData.joisd_skills_required.map((skill, i) => {
                return (
                  <h1 key={i} className="joi-se-elm">
                    {skill.joisd_sr_txt}
                  </h1>
                );
              })}
          </div>
        </div>
        <div className="joi-description-container">
          {sectionData &&
            sectionData.joisd_description_parragraph.map((p, i) => {
              return (
                <p key={i} className="joi-desc-parragraph">
                  {p.joisd_dp_txt}
                </p>
              );
            })}
        </div>
        {sectionData?.hasObjectivesSection && (
          <div className="joi-func-container">
            <h1 className="joi-func-title">¿Cuál será tu objetivo?</h1>
            <ul className="joi-func-list-container">
              {sectionData &&
                sectionData.joisd_objective_list_data.map((obj, i) => {
                  return (
                    <li key={i} className="joi-func-list-elm">
                      {obj.joisd_old_txt}
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
        <div className="joi-benefits-container">
          <h1 className="joi-benefits-title">
            ¿Cuáles son los beneficios contractuales?
          </h1>
          <ul className="joi-benefits-list-container">
            {sectionData &&
              sectionData.joisd_benefit_list_data.map((benefit, i) => {
                return (
                  <li key={i} className="joi-benefits-list-elm">
                    {benefit.joisd_bld_txt}
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="joi-requirements-container">
          <h1 className="joi-requirements-title">
            ¿Qué requisitos debes cumplir?
          </h1>
          <ul className="joi-requirements-list-container">
            {sectionData &&
              sectionData.joisd_requirement_list_data.map((req, i) => {
                return (
                  <li key={i} className="joi-requirements-list-elm">
                    {req.joisd_rld_txt}
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="joi-skills-container">
          <h1 className="joi-skills-title">Habilidades</h1>
          <ul className="joi-skills-list-container">
            {sectionData &&
              sectionData.joisd_other_skills_list_data.map((other_skill, i) => {
                return (
                  <li key={i} className="joi-skills-list-elm">
                    {other_skill.joisd_osld_txt}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default JobOfferInfoSection;
