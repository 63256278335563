import React from "react";

//Style
import "./StaticBannerComponent.css";

const StaticBannerComponent = ({ bannerImg, height }) => {
  return (
    <div className="static-banner-container">
      <img src={bannerImg} alt="" className="sb-img" height={height} />
    </div>
  );
};

export default StaticBannerComponent;
